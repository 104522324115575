export default {
  name: "ProcessTSavingBlock",
  data() {
    return {
      identity: {
        accountNumber: "",
        savingAccountId: "",
        cifIdName: "",
        balanceEnd: "",
      },
      identifier: {
        savingBlockIdSelected: "",
      },
      property: {
        modal: {
          showModalUnblockSavingBlock: false,
        },
        listElement: {
          savingBlock: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        animation: {
          block: {
            isLoading: false,
          },
          unblock: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        block: {
          registedNumber: "",
          nominalBlocked: 0,
          blockedTypeId: "",
          blockedReasonId: "",
          blockedDate: "",
          blockedDueDate: "",
          description: "",
        },
        unblock: {
          registedNumber: "",
          nominalBlocked: 0,
          blockedTypeId: "",
          blockedReasonId: "",
          blockedDate: "",
          blockedDueDate: "",
          description: "",
        },
      },
      options: {
        jenisBlokir: [],
        alasanBlokir: [],
      },
      table: {
        header: {
          savingBlock: [],
        },
        data: {
          savingBlock: [],
        },
      },
    };
  },
  methods: {
    changeBlockType() {
      var blockTypeText = this.options.jenisBlokir.find(
        (data) => data.value === this.dataForm.block.blockedTypeId
      );
      this.dataForm.block.description = `${blockTypeText.text} An ${this.identity.cifIdName}`;
    },
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem(
        "ACCOUNT_NUMBER_SAVING_ACCOUNT"
      );

      this.identity.accountNumber = getTokenFromSession;
    },
    async getSavingAccountById() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl:
            "saving-account/find-cross-office/" + this.identity.accountNumber,
        });
        if (resp.data.code === "SUCCESS") {
          this.identity.accountNumber = resp.data.data.accountNumber;
          this.identity.savingAccountId = resp.data.data.savingAccountId;
          this.identity.cifIdName = resp.data.data.qqName
            ? `${resp.data.data.cifName} ${resp.data.data.qqCode} ${resp.data.data.qqName}`
            : resp.data.data.cifName;
          this.identity.balanceUsable = resp.data.data.balanceUsable
            ? resp.data.data.balanceUsable
            : 0;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListSavingBlockProcess(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () => this.routeToPageListSavingBlockProcess(),
        });
      }
    },
    async getSavingAccountBlockById(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "saving-block/" + props.row.savingBlockedId,
          });
          if (resp.data.code === "SUCCESS") {
            this.identifier.savingBlockIdSelected = props.row.savingBlockedId;
            this.dataForm.unblock.registedNumber =
              resp.data.data.registedNumber;
            this.dataForm.unblock.nominalBlocked =
              resp.data.data.nominalBlocked;
            this.dataForm.unblock.blockedTypeId = resp.data.data.blockedType
              ? resp.data.data.blockedType.blockedTypeId
              : "";
            this.dataForm.unblock.blockedReasonId = resp.data.data.blockedReason
              ? resp.data.data.blockedReason.blockedReasonId
              : "";
            this.dataForm.unblock.blockedDate = resp.data.data.blockedDate;
            this.dataForm.unblock.blockedDueDate =
              resp.data.data.blockedDueDate;
            this.dataForm.unblock.description = resp.data.data.description;
            this.property.modal.showModalUnblockSavingBlock = true;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async unblockSavingAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Buka Blokir Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.unblock.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "transaction",
                reqUrl:
                  "saving-block/unblock/" +
                  this.identifier.savingBlockIdSelected,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.property.modal.showModalUnblockSavingBlock = false;
                  setTimeout(() => {
                    this.$buefy.toast.open(this.$NotificationUtils.success);
                    setTimeout(() => {
                      this.resetFormSavingAccountBlock("UNBLOCK");
                      this.getSavingAccountBlock();
                    }, 500);
                  }, 1000);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.unblock.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    appendDefaultInput() {
      this.dataForm.block.registedNumber = "1";
    },
    async authorizationSavingBlocked(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl: "saving-block/authorized/" + props.row.savingBlockedId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getSavingAccountBlock();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    closeModalUnblockSavingAccount() {
      this.resetFormSavingAccountBlock("UNBLOCK");
      this.property.modal.showModalUnblockSavingBlock = false;
    },
    resetFormSavingAccountBlock(type) {
      switch (type) {
        case "BLOCK":
          this.identifier.savingBlockIdSelected = "";
          this.dataForm.block.registedNumber = "";
          this.dataForm.block.nominalBlocked = 0;
          this.dataForm.block.blockedTypeId = "";
          this.dataForm.block.blockedReasonId = "";
          this.dataForm.block.blockedDate = "";
          this.dataForm.block.blockedDueDate = "";
          this.dataForm.block.description = "";

          break;
        case "UNBLOCK":
          this.identifier.savingBlockIdSelected = "";
          this.dataForm.unblock.registedNumber = "";
          this.dataForm.unblock.nominalBlocked = 0;
          this.dataForm.unblock.blockedTypeId = "";
          this.dataForm.unblock.blockedReasonId = "";
          this.dataForm.unblock.blockedDate = "";
          this.dataForm.unblock.blockedDueDate = "";
          this.dataForm.unblock.description = "";
          break;

        default:
          return;
          break;
      }
    },
    routeToPageListSavingBlockProcess() {
      this.$router.push("list");
    },
    getReferenceBlockedReason() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "blocked-reason",
        })
        .then((resp) => {
          resp.data.data.map((index) => {
            const text = index.blockedReasonName;
            const value = index.blockedReasonId;
            this.options.alasanBlokir.push({ text, value });
          });
        });
    },
    async addBlockSavingAccount() {
      const payload = {
        accountNumber: this.identity.accountNumber,
        registedNumber: this.dataForm.block.registedNumber,
        blockedTypeId: this.dataForm.block.blockedTypeId,
        nominalBlocked: this.dataForm.block.nominalBlocked,
        blockedReasonId: this.dataForm.block.blockedReasonId,
        blockedDate: this.dataForm.block.blockedDate,
        blockedDueDate: this.dataForm.block.blockedDueDate,
        description: this.dataForm.block.description,
        statusId: "1",
      };
      console.log(JSON.stringify(payload));
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Blokir Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.block.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "saving-block/block",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  setTimeout(() => {
                    this.resetFormSavingAccountBlock("BLOCK");
                    this.getSavingAccountBlock();
                  }, 500);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.block.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    appendDefaultOptions() {
      this.options.jenisBlokir.push({ value: "", text: "-- Pilih --" });
      this.options.alasanBlokir.push({ value: "", text: "-- Pilih --" });
    },
    handleErrorSavingBlock(error) {
      console.log(error.response);
      this.table.data.savingBlock = [];
      this.property.listElement.savingBlock.rows = 0;
      this.property.listElement.savingBlock.currentPage = 1;
      this.property.listElement.savingBlock.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    clearTableSavingBlock() {
      this.table.data.savingBlock = [];
      this.property.listElement.savingBlock.rows = 0;
      this.property.listElement.savingBlock.currentPage = 1;
      this.property.listElement.savingBlock.downloading = true;
    },
    async getSavingAccountBlock() {
      this.clearTableSavingBlock();
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-block",
          payload: {
            accountNumber: this.identity.accountNumber,
            blockedTypeId: "",
            page: 0,
            size: this.property.listElement.savingBlock.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingBlock = resp.data.data.content;
            this.property.listElement.savingBlock.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataSavingBlockProcess(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSavingBlock(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.savingBlock.downloading = false;
        }, timeout);
      }
    },
    async changePaginationProcessSavingBlock(event) {
      this.table.data.savingBlock = [];
      this.property.listElement.savingBlock.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-block",
          payload: {
            accountNumber: this.identity.accountNumber,
            blockedTypeId: "",
            page: event - 1,
            size: this.property.listElement.savingBlock.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingBlock = resp.data.data.content;
            this.property.listElement.savingBlock.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataSavingBlockProcess(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSavingBlock(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.savingBlock.downloading = false;
        }, timeout);
      }
    },
    failedGetDataSavingBlockProcess(resp) {
      this.table.data.savingBlock = [];
      this.property.listElement.savingBlock.rows = 0;
      this.property.listElement.savingBlock.message = resp.data.message;
    },
    getReferenceBlockedType() {
      this.$store
        .dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "blocked-type",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.blockedTypeId;
            const text = i.blockedTypeName;
            this.options.jenisBlokir.push({ text, value });
          });
        });
    },
    clearIdentifierAccountNumberSavingAccountOnSessionStorage() {
      sessionStorage.removeItem("ACCOUNT_NUMBER_SAVING_ACCOUNT");
    },
    tokenAvailabilityChecker() {
      const token = sessionStorage.getItem("ACCOUNT_NUMBER_SAVING_ACCOUNT");
      if (!token) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListSavingBlockProcess();
      }
    },
  },
  beforeMount() {
    this.tokenAvailabilityChecker();
  },
  async mounted() {
    this.getIdentityFromSession();
    await this.getSavingAccountById();
    this.getSavingAccountBlock();
    this.getReferenceBlockedType();
    this.getReferenceBlockedReason();
    this.appendDefaultInput();
    this.appendDefaultOptions();
  },
  destroyed() {
    this.clearIdentifierAccountNumberSavingAccountOnSessionStorage();
  },
};
